<template>
  <h4 class="font-normal my-0 mr-3">Show by date</h4>
  <div class="field-radiobutton mb-0 mr-3">
    <RadioButton
      v-model="dateSwitcher"
      inputId="dateToday"
      name="date"
      value="today"
      :disabled="isLoading"
      @change="changeDateFilter" />
    <label for="dateToday">Today</label>
  </div>
  <div class="field-radiobutton mb-0 mr-3">
    <RadioButton
      v-model="dateSwitcher"
      inputId="dateYesterday"
      name="date"
      value="yesterday"
      :disabled="isLoading"
      @change="changeDateFilter" />
    <label for="dateYesterday">Yesterday</label>
  </div>
  <div class="field-radiobutton mb-0 mr-3">
    <RadioButton
      v-model="dateSwitcher"
      inputId="dateCustom"
      name="date"
      value="custom"
      :disabled="isLoading"
      @change="changeDateFilter" />
    <label for="dateCustom">Custom</label>
  </div>

  <Calendar
    v-model="dateRange"
    :disabled="isLoading"
    inputId="range"
    selectionMode="range"
    dateFormat="dd-mm-yy"
    showIcon
    hideOnRangeSelection
    inputClass="w-15rem"
    :manualInput="false"
    @click="dateSwitcher = 'custom'"
    @update:modelValue="changeDateFilter" />
</template>

<script setup>
import RadioButton from "primevue/radiobutton";
import Calendar from "primevue/calendar";
import { ref } from "vue";
import { dateConvertor } from "@/utils";

defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const $emit = defineEmits(["updateFilterDate"]);

let dateSwitcher = ref("");
let dateRange = ref([]);

const changeDateFilter = () => {
  let startDate;
  let endDate;
  if (dateSwitcher.value === "today") {
    dateRange.value = [];
    startDate = endDate = dateConvertor(Date.now());
  }
  if (dateSwitcher.value === "yesterday") {
    dateRange.value = [];
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    startDate = endDate = dateConvertor(currentDate);
  }
  if (dateSwitcher.value === "custom" && dateRange.value[1]) {
    startDate = dateConvertor(dateRange.value[0]);
    endDate = dateConvertor(dateRange.value[1]);
  }
  if (!startDate || !endDate) {
    return;
  }
  $emit("updateFilterDate", "startDate", startDate);
  $emit("updateFilterDate", "endDate", endDate);
};
</script>

<style scoped></style>
