import axiosInstance from "@/api/apiConfig";

export const getItemsFunction = async (url, requestParams) => {
  let params = {};
  Object.keys(requestParams).forEach((key) => {
    if (requestParams[key]) {
      params[key] = requestParams[key];
    }
  });
  const { data, allItems, allPage, perPage, page } = await axiosInstance.get(url, {
    params,
  });

  return {
    pagination: {
      allItems,
      allPage,
      perPage,
      page,
    },
    data,
  };
};
