<template>
  <div class="flex align-items-center">
    <Btn
      class="bg-yellow-500 border-yellow-500 mr-2"
      label="Send Invoice"
      data-cy="sendInvoice"
      :loading="currentLoadingAction === apiActions.sendRequest"
      :disabled="!!currentLoadingAction && currentLoadingAction !== apiActions.sendRequest"
      @click="onClickAction(apiActions.sendRequest)" />
    <Btn
      class="bg-purple-500 border-purple-500 mr-2"
      label="Download Invoice"
      :loading="currentLoadingAction === apiActions.downloadRequest"
      :disabled="!!currentLoadingAction && currentLoadingAction !== apiActions.downloadRequest"
      @click="onClickAction(apiActions.downloadRequest)" />
    <Btn
      v-if="!apiActions.syncRequests.subType"
      class="bg-red-500 border-red-500"
      label="Synch"
      :loading="currentLoadingAction === apiActions.syncRequests.default"
      :disabled="!!currentLoadingAction && currentLoadingAction !== apiActions.syncRequests.default"
      @click="onClickAction(apiActions.syncRequests.default)" />
    <SplitButton
      v-else
      class="custom-split-btn bg-red-500 border-red-500 pr-4"
      label="Synch"
      :buttonProps="{ class: 'bg-red-500 border-red-500' }"
      :menuButtonProps="{
        class:
          'bg-transparent border-red-500 border-round absolute right-0 w-full block text-right pr-2',
      }"
      :loading="currentLoadingAction === apiActions.syncRequests.default"
      :disabled="!!currentLoadingAction && currentLoadingAction !== apiActions.syncRequests.default"
      :model="syncOptions" />
  </div>
</template>

<script setup>
import Btn from "primevue/button";
import SplitButton from "primevue/splitbutton";
import { useToast } from "primevue/usetoast";
import { ref } from "vue";
import * as API from "@/api/invoicesService";

const $toast = useToast();
const $props = defineProps({
  apiActions: {
    type: Object,
    required: true,
  },
  selectedInvoices: {
    type: Array,
    default: () => [],
  },
});
const $emit = defineEmits(["onLoading", "onActionFinished"]);

let currentLoadingAction = ref("");

const syncOptions = [
  {
    label: "Synch",
    icon: "pi pi-refresh",
    command: () => {
      onClickAction($props.apiActions.syncRequests.default);
    },
  },
  {
    label: "Synch Stripe",
    icon: "pi pi-refresh",
    command: () => {
      onClickAction($props.apiActions.syncRequests.default, $props.apiActions.syncRequests.subType);
    },
  },
];
const warnUser = () => {
  $toast.add({
    severity: "warn",
    summary: "Sorry",
    detail: "You need to select at least one invoice for this action!",
    life: 5000,
  });
};

const onClickAction = async (type, isSubtype = false) => {
  if (!$props.selectedInvoices.length) {
    warnUser();
    return;
  }
  currentLoadingAction.value = type;
  $emit("onLoading", true);
  const invoicesIds = $props.selectedInvoices.map(({ id }) => id);
  const { message, success } = await API[type](invoicesIds, {
    isSubtype,
    isShopify: $props.isShopify,
  });
  $emit("onActionFinished");
  $toast.add({
    severity: success ? "success" : "error",
    summary: success ? "Success" : "Error",
    detail: message,
    life: 5000,
  });
  currentLoadingAction.value = "";
  $emit("onLoading", false);
};
</script>

<style lang="scss"></style>
