// Invoices
import axiosInstance from "@/api/apiConfig";
import { dateConvertor, fileSaver } from "@/utils";
import { getItemsFunction } from "@/utils/apiHelper";

export const getInvoicesRequest = async (url, requestParams) => {
  const { data, pagination } = await getItemsFunction(url, requestParams);
  return {
    pagination,
    data: data.map(
      ({ id, status, amountChf, customerName, number, dateCreate, dateEnd, dunningLevel }) => ({
        id,
        status,
        amountDue: amountChf,
        customerName,
        number,
        created: `${dateConvertor(dateCreate, true, ".")}
        ${dateEnd ? `-${dateConvertor(dateEnd, true, ".")}` : ""}`,
        dateEnd,
        dunningLevel,
      })
    ),
  };
};
export const sendInvoicesRequest = async (invoicesIds) => {
  try {
    return await axiosInstance.post("invoices/download/or/send", {
      number: invoicesIds,
      lamp: "send",
    });
  } catch (e) {
    return { success: false, message: e.message || "System Error" };
  }
};
export const downloadInvoicesRequest = async (invoicesIds) => {
  try {
    const { message, success, fileLink, fileName } = await axiosInstance.post(
      "invoices/download/or/send",
      {
        number: invoicesIds,
        lamp: "download",
      }
    );
    fileSaver(fileLink, fileName);
    return { success, message };
  } catch (e) {
    return { success: false, message: e.message || "System Error" };
  }
};
export const syncInvoicesRequest = async (invoicesIds, isSubtype) => {
  try {
    return await axiosInstance.post(`invoices/synch${isSubtype ? "/db" : ""}`, {
      number: invoicesIds,
    });
  } catch (e) {
    return { success: false, message: e.message || "System Error" };
  }
};
// Shopify Invoices
export const sendShopifyInvoicesRequest = async (invoicesIds) => {
  try {
    return await axiosInstance.post("invoices/shopify/actions", {
      number: invoicesIds,
      lamp: "send",
    });
  } catch (e) {
    return { success: false, message: e.message || "System Error" };
  }
};
export const downloadShopifyInvoicesRequest = async (invoicesIds) => {
  try {
    const { message, success, fileLink, fileName } = await axiosInstance.post(
      "invoices/shopify/actions",
      {
        number: invoicesIds,
        lamp: "download",
      }
    );
    fileSaver(fileLink, fileName);
    return { success, message };
  } catch (e) {
    return { success: false, message: e.message || "System Error" };
  }
};
export const syncShopifyInvoicesRequest = async (invoicesIds) => {
  try {
    return await axiosInstance.post(`invoices/shopify/synch`, {
      number: invoicesIds,
    });
  } catch (e) {
    return { success: false, message: e.message || "System Error" };
  }
};
