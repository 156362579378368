<template>
  <Card class="mb-3">
    <template #content>
      <div class="flex align-items-center mb-5">
        <StatusFilter
          :statusesList="invoiceStatuses"
          title="Payment status"
          :isLoading="isLoading"
          :currentSelected="requestParams.invoiceStatus"
          @onStatusChange="changeParams('invoiceStatus', $event)" />
        <Dropdown
          :modelValue="requestParams.dunningStatus || null"
          :options="dunningStatuses"
          :showClear="true"
          :loading="isLoading"
          optionLabel="title"
          optionValue="value"
          placeholder="All dunning status"
          class="ml-auto"
          :autoOptionFocus="false"
          @update:modelValue="changeParams('dunningStatus', $event)" />
      </div>
      <div class="flex align-items-center">
        <DateFilter :isLoading="isLoading" @updateFilterDate="changeParams" />
        <div class="ml-auto flex align-items-center">
          <div class="p-input-icon-left p-input-icon-right">
            <i class="pi pi-search" />
            <i v-show="isLoading" class="pi pi-spin pi-spinner" />
            <InputText
              :modelValue="requestParams.searchText"
              placeholder="Search invoice"
              @input="changeParams('searchText', $event.target.value.trim())" />
          </div>
        </div>
      </div>
      <div class="flex w-full mb-2 relative">
        <Tag
          v-show="isListFiltered"
          class="cursor-pointer absolute left-0"
          style="bottom: -33px"
          icon="pi pi-times"
          severity="danger"
          value="Reset all filters"
          @click="$emit('onResetAllFilters')" />
      </div>
    </template>
  </Card>
</template>

<script setup>
import { computed, reactive } from "vue";

import Card from "primevue/card";
import Tag from "primevue/tag";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";

import DateFilter from "@/components/invoices/DateFilter";
import StatusFilter from "@/components/elements/StatusFilter";

const $props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  requestParams: {
    type: Object,
    required: true,
  },
  invoiceStatusesList: {
    type: Array,
    required: true,
  },
});

const $emit = defineEmits(["paramsUpdated", "onResetAllFilters"]);

let isListFiltered = computed(() => {
  return Object.keys($props.requestParams).some((key) => {
    return !!$props.requestParams[key];
  });
});

const invoiceStatuses = reactive($props.invoiceStatusesList);
const dunningStatuses = reactive([
  { title: "Zahlungserinnerung", value: "1" },
  { title: "2. Mahnung", value: "2" },
  { title: "Letzte Mahnung", value: "3" },
]);

const changeParams = (params, value) => {
  $emit("paramsUpdated", { params, value });
};
</script>

<style scoped></style>
