<template>
  <PageHeader>
    <InvoicesActions
      :selectedInvoices="selectedInvoices"
      :apiActions="apiActions"
      @onLoading="isLoading = $event"
      @onActionFinished="selectedInvoices = []" />
  </PageHeader>
  <InvoicesFilters
    :key="updateKey"
    :isLoading="isLoading"
    :requestParams="requestParams"
    :invoiceStatusesList="invoiceStatusesList"
    @onResetAllFilters="resetAllFilters"
    @paramsUpdated="updateParams" />
  <InvoicesTable
    v-model:selection="selectedInvoices"
    :invoices="invoices"
    :isLoading="isLoading"
    :perPage="pagination.perPage"
    :allItems="pagination.allItems"
    :page="pagination.page"
    :sortingParam="requestParams.sortingParam"
    @page="updatePagination"
    @onParamsUpdated="updateParams" />
</template>
<script setup>
import PageHeader from "@/components/elements/PageHeader";
import InvoicesTable from "@/components/invoices/InvoicesTable";
import InvoicesFilters from "@/components/invoices/InvoicesFilters";
import InvoicesActions from "@/components/invoices/InvoicesActions";

import { onBeforeMount, reactive, ref } from "vue";
import { getInvoicesRequest } from "@/api/invoicesService";

const $props = defineProps({
  apiActions: {
    type: Object,
    required: true,
  },
  getInvoicesUrl: {
    type: String,
    required: true,
  },
  invoiceStatusesList: {
    type: Array,
    required: true,
  },
});

const defaultParams = {
  invoiceStatus: "",
  dunningStatus: "",
  startDate: "",
  endDate: "",
  searchText: "",
  sortingColumn: null,
  sortingParam: null,
};
let isLoading = ref(false);
let invoices = ref([]);
let updateKey = ref(0);
let requestParams = reactive({ ...defaultParams });
let pagination = reactive({
  perPage: 10,
  allItems: 10,
  page: 1,
  allPage: 0,
});
let selectedInvoices = ref([]);

const updateParams = ({ params, value }) => {
  if (requestParams[params] === value) {
    return;
  }
  requestParams[params] = value;
  if (["startDate", "sortingColumn"].includes(params)) {
    return;
  }
  getInvoices();
};

const updatePagination = ({ page, rows }) => {
  if (pagination.perPage !== rows) {
    pagination.perPage = rows;
    getInvoices();
    return;
  }
  if (pagination.page !== page + 1) {
    pagination.page = page + 1;
    getInvoices();
  }
};

const resetAllFilters = () => {
  Object.assign(requestParams, defaultParams);
  updateKey.value++;
  getInvoices();
};

const getInvoices = async () => {
  try {
    isLoading.value = true;
    const { data, pagination: updatedPagination } = await getInvoicesRequest(
      $props.getInvoicesUrl,
      {
        ...requestParams,
        page: pagination.page,
        perPage: pagination.perPage,
      }
    );
    pagination.page = +updatedPagination.page;
    pagination.perPage = +updatedPagination.perPage;
    pagination.allItems = +updatedPagination.allItems;
    pagination.allPage = +updatedPagination.allPage;
    invoices.value = data;
  } catch (e) {
    console.warn("getInvoices error", e);
  } finally {
    isLoading.value = false;
  }
};

onBeforeMount(() => {
  getInvoices();
});
</script>
<style scoped></style>
